import React from "react"
import { useTheme } from "emotion-theming"
import BlockContent from "@sanity/block-content-to-react"

import { Grid } from "@sonos-inc/web-ui-core"

import serializers from "./portableTextSerializers"

export default function AboutPageContent({ _rawBodyCopy }) {
  const theme = useTheme()
  return (
    <Grid
      gridTemplateColumns={`2fr repeat(${theme.grid.columns - 4}, 1fr) 2fr`}
    >
      <BlockContent blocks={_rawBodyCopy} serializers={serializers} />
    </Grid>
  )
}
