import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import AboutPageContent from "../components/AboutPageContent"
import SEO from "../components/SEO"

export default function AboutPage({ data: { sanityPageTechblog } }) {
  return (
    <Layout>
      <SEO title="About" />
      <AboutPageContent {...sanityPageTechblog} />
    </Layout>
  )
}

export const aboutPageData = graphql`
  query {
    sanityPageTechblog(slug: { current: { eq: "about" } }) {
      title
      _rawBodyCopy(resolveReferences: { maxDepth: 5 })
    }
  }
`
